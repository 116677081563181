.wrapper {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 184px;
    margin-bottom: 184px;
    position: relative;

}

.inner {
    width: 800px;
    max-width: 100%;
    position: relative;
    // background: #6f14ff2e;
    background: #6d76ff24;
    padding: 64px 48px;
    padding-right: 120px;
    margin: 12px;
    border-radius: 10px;
    border: 1px solid #c4caff;
    // box-shadow: 0 11px 16.4px -3.2px rgba(0, 0, 0, 0.1), 0 4.38px 6.5px -4.38px rgba(0, 0, 0, 0.1);
}

.title {
    font-size: 34px;
    margin-bottom: 28px;
}

.tag {
    font-weight: 900;
    font-size: 16px;
    padding: 6px 16px;
    margin-bottom: 22px;
    // color: #ffffff !important;
    // background: #2f5b44 !important;
}

.actions a {
    text-decoration: underline;
}

.image {
    position: absolute;
    right: -40px;
    bottom: calc(50% - 60px);
}

@media (max-width: 996px) {
    .image {
        position: absolute;
        right: 20px;
        bottom: calc(50% - 60px);
    }

    .inner {
        padding-right: 180px;
    }
}

@media (max-width: 650px) {
    .image {
        position: absolute;
        top: 10px;
        left: 34px;
    }

    .inner {
        padding: 64px 48px;
        padding-top: 180px;
    }
}