.main {
  background-color: #ffffff;
  padding-bottom: 40px;
  padding-top: 56px;
}

.tag {
  border: none;
  font-weight: 900;
  font-size: 16px;
  line-height: 34px;
  padding-inline: 14px;
}

.firstSectionWrapper {
  margin-right: 12px;
  margin-left: 12px;
}

.firstSectionInner {
  display: flex;
  width: 1150px;
  max-width: 100%;
  margin: auto;
  padding-bottom: 64px;
}

.headerSection {
  flex-basis: 100%;
  max-width: 100%;

}

.headerSectionInner {
  margin-left: 12px;
  margin-right: 12px;
  text-align: center;
}

.headerSectionRight {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

}

.mainTitle {
  margin: auto;
  width: 734px;
  max-width: 100%;
  font-size: 44px;
  margin-top: 2px;
  margin-bottom: 16px;
}

.headerQuote {
  font-size: 28px;
  width: 420px;
  line-height: 41px;
  text-align: center;
  padding: 20px 50px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headerSectionQuote {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circleBackground {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #e8e8e8;
  border-radius: 50%;
  width: 580px;
  height: 400px;
  z-index: 1;
  transform: rotate3d(0, 1, 1, 50deg)
}

.number {
  font-size: 42px;
}

.subtitle {
  margin: auto;
  font-size: 23px;
  line-height: 34px;
  width: 660px;
  max-width: 100%;
}

.actions {
  margin-top: 34px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
}

.landingQuote {
  text-align: center;
  font-size: 32px;
  padding-top: 44px;
  padding-bottom: 44px;
  font-weight: 100;
  width: 100%;
  // background: #f1f1a0;``
  background: #020849;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: white;
  margin-bottom: 142px;

  .landingQuoteInner {
    margin: 12px;
  }
}

.productsInner {
  padding-top: 44px;
  padding-bottom: 44px;
  display: flex;
  justify-content: center;
}

.argumentsWrapper {
  margin-left: 24px;
  margin-right: 24px;
}

.argumentsInner {
  width: 1200px;
  max-width: 100%;
  margin: auto;
}

.logos {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.oneLogo {
  margin-right: 12px;
}

.promotedApp {
  padding: 24px;
  background: #d1d2ff;
  width: 440px;
  max-width: 100%;
  display: flex;
  align-items: center;
  margin-top: 44px;
  border-radius: 22px;
}

.productTitle {
  text-align: center;
  font-size: 34px;
  margin-bottom: 12px;
}

.productsubtitle {
  text-align: center;

}

.productTop {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 740px;
  margin: auto;
  margin-top: 24px;
  max-width: 100%;
}

.Page_inner {

  h1,
  h2,
  h3 {
    margin-bottom: 14px;
    margin-top: 34px;
  }

  ul {
    list-style: none;
    padding-inline-start: 40px;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
}

.privacy_wrapper {
  width: 800px;
  max-width: 100%;
  margin: auto;
}

.productTopComingSoon {
  position: absolute;
  bottom: -14px;
  right: 16px;
  border: none;
  z-index: 2;

}

.actionsInProductList {
  display: flex;
  justify-content: center;
  margin-bottom: 84px;
}

.ratedTopPanel {
  width: 600px;
  max-width: 100%;
  margin: auto;
}

.g2LogosTop {
  margin-bottom: 0 !important;

  a {
    color: #37525d !important;
  }
}

.dreamTeamWrapper {
  text-align: center;
}

.bullshitTag {
  font-size: 22px;
  padding: 8px 14px;
  margin-bottom: 8px;
}

.whyWrapper {
  text-align: center;
  margin-top: 142px;
  margin-bottom: 142px;

  h2 {
    margin-bottom: 12px;
    font-size: 34px;
  }
}

.whySubtitle {
  width: 700px;
  margin: auto;
}

.finalCall {
  text-align: center;
  margin-top: 144px;
  margin-bottom: 144px;
  margin-left: 12px;
  margin-right: 12px;

  h2 {
    margin-bottom: 18px;
    font-size: 34px;
  }
}

.companyLogosTop {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  filter: grayscale(1) contrast(0.1);
  opacity: 0.6;

  max-width: 100%;
  margin: auto;
  margin-bottom: 12px;

  img {
    margin: 18px;
    margin-bottom: 4px;
  }
}

/* Mobile */
@media (max-width: 900px) {
  .firstSectionInner {
    flex-wrap: wrap;
  }

  .headerSection {
    flex-basis: 100%;
    max-width: 100%;
  }

  .productsInner {
    flex-wrap: wrap;
  }
}




@media (max-width: 800px) {
  .productTop {
    flex-wrap: wrap;
  }

  .connect,
  .celebrate,
  .recognize,
  .listen {
    width: 190px;
    justify-content: center;
  }

}

@media (max-width: 700px) {
  .mainTitle {
    margin-top: 0px;
    margin-bottom: 16px;
    font-size: 34px;
  }

  .main {
    padding-top: 26px;
  }

}

@media (max-width: 500px) {
  .subtitle {
    font-size: 20px;
  }

  .mainTitle {
    font-size: 30px;
  }

  .main {
    padding-top: 16px;
  }
}

@media (max-width: 400px) {
  .mainTitle {
    font-size: 28px;
  }

}

@media (max-width: 380px) {
  .mainTitle {
    font-size: 26px;
  }

}