.wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 990px;
    margin: auto;
    max-width: 100%;
}

.element {
    margin: 12px;
    max-width: 100%;
    font-size: 18px;
    line-height: 24px;
    // background-color: #f0f0f0;
    border-radius: 10px;
    text-align: left;
    display: flex;
    align-items: center;
}

.tag {
    font-weight: bold;
    font-size: 16px;
}

.wrapperSection {
    width: 1200px;
    max-width: 100%;
    margin: auto;
    padding-top: 12px;
    padding-bottom: 24px;

    h2 {
        margin-bottom: 24px;
    }
}
.icon{
    margin-right: 8px;
    color: #20c811;
}
.titleFlex {
    display: flex;
    width: 720px;
    max-width: 100%;
    margin: auto;

    .titleFlex1 {
        flex-basis: 50%;
        margin: 12px;

        h2 {
            font-size: 34px;
        }
    }

    .titleFlex2 {
        flex-basis: 50%;
        font-size: 18px;
        line-height: 24px;
        margin: 12px;
    }
}

.number {
    color: #20c811;
    margin-right: 8px;
}

@media (max-width: 900px) {
    .titleFlex {
        flex-wrap: wrap;

        .titleFlex1,
        .titleFlex2 {
            flex-basis: 100%;
        }
    }

    .wrapper {
        justify-content: center;
    }


}