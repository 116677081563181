ul.list {
    padding-inline-start: 0px;
    list-style: none;
    margin-bottom: 24px;


    li:before {
        content: "❌";
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 4px;
    }


}

.wrapper {
    position: relative;
    padding-top: 80px;
    padding-bottom: 44px;
    margin-bottom: 80px;
    background: #fff0f0;

}

.stop {
    position: absolute;
    right: -80px;
    font-size: 185px;
    opacity: 0.3;
    top: -60px;
    user-select: none;
}

.title {
    margin-bottom: 12px;
    font-size: 34px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.inner {
    margin-left: 12px;
    margin-right: 12px;
}