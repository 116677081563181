.title {
    text-align: center;
    margin-bottom: 12px;
}

.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.itemResponse {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    font-weight: bold;
}

.gaugeGreyMedium,
.gaugeGreenLarge,
.gaugeGreySmall,
.gaugeGreyMedium1 {
    border-radius: 10px;
    background-color: #c7c7c7;
    height: 36px;
    margin-right: 8px;
}

.gaugeGreyMedium {
    width: 50%;
}

.gaugeGreyMedium1 {
    width: 25%;
}

.gaugeGreySmall {
    width: 10%;
}

.gaugeGreenLarge {
    width: 100%;
    background-color: #11c811;
}

.surveyTitle {
    margin-bottom: 22px;
    text-align: left;
}

.by {
    min-width: 140px;
    text-align: left;
}

.subtitle {
    margin: auto;
    margin-bottom: 32px;
    text-align: center;
    width: 780px;
    max-width: 100%;
}

.hintWrapper {
    display: flex;
    align-items: center;
    color: grey;
    border-top: 1px solid #e8e8e8;
    padding-top: 12px;
    margin-top: 22px;

    .hintContent {
        font-size: 14px;
        margin-left: 6px;
        text-align: left;
    }
}

.input {
    width: 100px;
    margin-right: 8px;
}

.savingContent,
.answersWrapper {
    width: 700px;
    max-width: 100%;
    padding: 44px;
    background: #f2f6ff;
    border: 1px solid #d4dff1;
    border-radius: 10px;
    margin-bottom: 24px;
    margin: 12px;
    min-height: 420px;
    display: flex;
    flex-direction: column;
}

.responses {
    margin-top: 10px;
    margin-bottom: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.savingTotal {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 12px;

    .nbSaved {
        font-size: 38px;
        font-weight: bold;
        margin-right: 8px;
        min-width: 200px;
        display: flex;
    }

    .sign {
        font-size: 38px;
        font-weight: bold;
        min-width: 28px;
    }

    .perYear {
        margin-left: 8px;
        margin-right: 12px;
        color: grey;
        min-width: 88px;
    }

    .nbSavedName {
        font-weight: bold;
        font-size: 20px;
        text-align: left;
    }
}


.gallupSays {
    font-size: 14px;
    font-weight: 400;
    margin-top: 3px;
}

@media screen and (max-width: 750px) {
    .by {
        font-size: 15px;
        min-width: 100px;
    }
}

@media screen and (max-width: 650px) {
    .savingTotal {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 450px) {

    .savingContent,
    .answersWrapper {
        padding: 20px;
    }
}