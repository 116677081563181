.wrapper {
    // background-color: #232028;
    background-color: #151514;
    padding-bottom: 84px;
    padding-top: 44px;
    overflow: hidden;
}

.title {
    text-align: center;
    color: #fff;
    margin-bottom: 24px;
    font-size: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.subtitle 
{
    text-align: center;
    color: #fff;
    margin-bottom: 24px;
}
.content {
    margin-bottom: 44px;
    margin-top: 64px;
    margin-left: 12px;
    margin-right: 12px;
}


.elementWrapper {
    margin-bottom: 54px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 11px 16.4px -3.2px rgba(0, 0, 0, .1), 0 4.38px 6.5px -4.38px rgba(0, 0, 0, .1);
    color: #ededed;
    background-color: #1f1e1d;
    border: 1px solid #343434;
    position: relative;
    margin-left: 26px;
    margin-right: 26px;
}

.elementInner {
    display: flex;
    position: relative;
    overflow: hidden;
}

.elementLeft,
.elementRight {
    flex-basis: 50%;
    text-align: center;
    position: relative;

    img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

.elementLeft {
    border-right: 4px solid #1f1e1d;
}

.elementRight {
    border-left: 4px solid #1f1e1d;
}

.elementLeft img {
    filter: grayscale(1)
}

.appPicture {
    position: absolute;
    left: calc(50% - 170px);
    bottom: -30px;
    z-index: 1;
}

.with {
    // font-family: "Playwrite IS", cursive;
    // font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 28px;
    margin-bottom: 2px;
    position: absolute;
    bottom: 8px;
    left: 12px;
    z-index: 1;
    color: white;
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 0 0 8px black;
    width: calc(100% - 24px);
}

.billy {
    padding: 10px;
    border-radius: 10px;
    width: 340px;
    height: auto;
    box-shadow: 2px 2px 12px black;
}

.clappy {
    border-radius: 10px;
    width: 340px;
    height: auto;
}

.bottom {
    padding: 24px 22px;
    min-height: 60px;
    margin-top: -8px;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    color: #ededed;
}

.bottomLogo {
    img {
        border-radius: 10px;
        margin-top: 8px;
    }
}

.bottomContent {
    padding-left: 12px;
    font-weight: bold;

    .bottomHow {
        margin-bottom: 4px;
        display: flex;
    }

    .bottomBenefit {
        display: flex;
    }

    .emoji {
        margin-right: 8px;
    }
}

.arrow {
    img {
        position: absolute;
        z-index: 1;
        width: 280px;
        height: auto;
        left: calc(50% - 140px);
        top: calc(50% - 40px);
        filter: drop-shadow(2px 4px 4px black);
    }
}

.finalCTA {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 22px;
}

.finalCTAWrapper {
    h3 {
        color: white;
        margin-bottom: 12px;
        line-height: 32px;
    }

    width: 100%;
    max-width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 26px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #343434;
    box-shadow: 0 11px 16.4px -3.2px rgba(0, 0, 0, 0.1),
    0 4.38px 6.5px -4.38px rgba(0, 0, 0, 0.1);
    color: #ededed;
    background-color: #1f1e1d;
    padding: 64px 44px;
}

@media (max-width: 900px) {
    .with {
        font-size: 24px;
    }
}

@media (max-width: 700px) {
    .arrow {
        img {
            width: 200px;
            height: auto;
            left: calc(50% - 100px);
            top: calc(50% - 40px);
        }
    }
}

@media (max-width: 580px) {
    .bottom {
        flex-wrap: wrap;

    }

    .bottomLogo {
        margin: auto;
        margin-bottom: 12px;
    }

    .elementInner {
        flex-wrap: wrap;
    }

    .elementLeft,
    .elementRight {
        flex-basis: 100%;
        border: none
    }

    .elementLeft {
        border-bottom: 4px solid #1f1e1d;
    }

    .arrow img {
        transform: rotate(90deg);
        left: calc(100% - 140px);
    }
}

@media (max-width: 500px) {
    .with {
        font-size: 22px;
    }

    .finalCTAWrapper {
        padding: 44px 28px;

        .finalCTA {
            flex-wrap: wrap;
        }
    }
}